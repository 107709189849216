

.gradient_color {
    .login_1 {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
        margin: 50px 0;
    }
    .Smiley h1 {
        font-size: 36px;
        font-family: "gotham-rounded" !important;
        font-weight: 600;
        color: #1e1e1e;
    }

    .bordset {
        border: 2px solid #ccc;
        padding: 5px 20px 20px 20px;
    }
    @media only screen and (min-width: 1025px) {
        .maxwidset {
            max-width: 38%;
        }
    }

}