.ManageAccountRoles {
    .SLDSEditableTable {
        table {
            table-layout: fixed;
            
            thead {
                th {
                    width: 30%;
                }
    
                th:first-child {
                    width: 40%;
                }
            }
        }
    }
}